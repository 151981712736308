import Vue from 'vue';
import App from './App.vue';
import {io} from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
import router from './router';
import './assets/css/main.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import i18n from "@/i18n/i18n";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import {store} from "@/store/store";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import vueDebounce from 'vue-debounce';
import Clipboard from 'v-clipboard';
import {vfmPlugin} from 'vue-final-modal';
import VueRouter from "vue-router";
import VueApexCharts from 'vue-apexcharts';
import {VueReCaptcha} from "vue-recaptcha-v3";
import VueLazyload from "vue-lazyload";

Vue.config.productionTip = false;
// Socket connection to Nest
const socket = io(process.env.VUE_APP_API_URL);

library.add(fas);

Vue.use(vueDebounce, {lock: true, defaultTime: '500ms'});

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true
  }
});
Vue.use(VueLazyload, {
  preLoad: 1.8,
  attempt: 1,
});
Vue.use(VueSocketIOExt, socket);
Vue.use(VueToast);
Vue.use(VueRouter);
Vue.use(Clipboard);
Vue.use(vfmPlugin);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

document.title = 'Accueil | SpaceCode';

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
