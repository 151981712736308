<template>
  <div id="app" :key="$route.path">
    <Particles />
    <div ref="screen">
      <router-view />
    </div>
    <modals-container />
  </div>
</template>

<script>
import Particles from "./components/Particles/Particles";
import {ModalsContainer} from 'vue-final-modal';
import party from "party-js";
import {REWARDS} from "./constants/dashboard/rewards";

export default {
  components: {Particles, ModalsContainer},
  sockets: {
    onReward(response) {
      if (!response.results.newReward) {
        return;
      }

      party.confetti(this.$refs.screen, {
        count: party.variation.range(200, 300),
        size: party.variation.range(1, 1),
        gravity: party.variation.range(1, 6),
        shapes: ["square"]
      });

      const displayName = REWARDS.find((reward) => reward.name === response.results.reward.name).displayName;

      this.$toast.success(`Tu as obtenu un nouveau badge : ${displayName}`, {
        duration: 10000
      });
    }
  }
};
</script>
<style>
  #app {
    color: #FFF;
  }
</style>

