import Vuex from 'vuex';
import {userModule} from "@/store/modules/userModule/userModule";
import Vue from "vue";
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
});

export const store = new Vuex.Store({
  modules: {
    user: userModule
  },
  plugins: [vuexLocal.plugin],
});
