import {isNull} from "@/utils/isNull";
import Router, {ROUTE_HOME} from "@/router";

export const userModule = {
  state: {
    user: {
      pseudo: null,
      id: null,
      token: null,
      socket_id: null,
      is_guest: true,
      room_id: null,
      room_state: null
    }
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },
    resetState: (state) => {
      state.user = {
        pseudo: null,
        id: null,
        token: null,
        is_guest: true,
        room_id: null,
        room_state: null
      };
    },
    updateSocketId: (state, socketId) => {
      state.user.socket_id = socketId;
    },
    addUserInRoom: (state, roomId) => {
      state.user.room_id = roomId;
    },
    removeUserFromRoom: (state) => {
      state.user.room_id = null;
      state.user.room_state = null;
    },
    updateRoomState: (state, stateRoom) => {
      state.user.room_state = stateRoom;
    }
  },
  actions: {
    login: ({commit}, user) => {
      commit('setUser', user);
    },
    logout: async ({commit}) => {
      commit('resetState');
      if (Router.currentRoute.name !== ROUTE_HOME.name){
        await Router.push(ROUTE_HOME.path);
      }
    },
    addUserInRoom: ({commit}, roomId) => {
      commit('addUserInRoom', roomId);
    },
    removeUserFromRoom: ({commit}) => {
      commit('removeUserFromRoom');
    },
    updateSocketId: ({commit}, socketId) => {
      commit('updateSocketId', socketId);
    },
    updateRoomState: ({commit}, stateRoom) => {
      commit('updateRoomState', stateRoom);
    }
  },
  getters: {
    getPseudo: (state) => state.user.pseudo,
    getGuestPseudo: (state) => state.user.pseudo,
    getUser: (state) => state.user,
    isNotConnectedOrIsGuest: (state) =>  isNull(state.user.id) || state.user?.is_guest
  }
};
