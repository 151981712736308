import VueRouter from 'vue-router';
import {store} from "@/store/store";

const RoomLink = () => import('../views/Room/RoomLink.vue');
const RoomContainer = () => import('../views/Room/RoomContainer');
const RoomConfiguration = () => import('../views/Room/RoomConfiguration.vue');
const RoomConnection = () => import('../views/Room/Redirects/RoomConnection.vue');
const RoomInvitation = () => import('../views/Room/Redirects/RoomInvitation.vue');
const RoomTeam = () => import('../views/Room/RoomTeam.vue');
const RoomCreation = () => import('../views/Room/Redirects/RoomCreation.vue');
const RoomAccount = () => import('../views/Room/RoomAccount.vue');
const RoomLose = () => import('../views/Room/RoomLose');
const RoomWin = () => import('../views/Room/RoomWin');
const Game = () => import('../views/Game/Game.vue');
const NotFound = () => import('../views/404');
const Activation = () => import('../views/Profile/Activation');
const Login = () => import('../views/Profile/Login');
const Register = () => import('../views/Profile/Register');
const Terms = () => import('../views/Terms');
const Contact = () => import('../views/Contact');
const Presentation = () => import('../views/Presentation');
const Faq = () => import('../views/Faq');
const ExercisesBundles = () => import('@/views/Exercises/ExercisesBundles');
const BundleConfiguration = () => import('../views/Bundles/BundleConfiguration');
const ExerciseConfiguration = () => import('../views/Exercises/ExerciseConfiguration');
const Dashboard = () => import('../views/Dashboard/Dashboard.vue');

const ResetPassword = () => import('../views/ResetPassword.vue');

const Home = () => import('../views/Home');

const Profile = () => import("@/views/Profile/Profile.vue");

export const ROUTE_HOME = {
  path: '/',
  name: 'home',
  component: Home
};

export const ROUTE_REGISTER = {
  path: '/register',
  name: 'register',
  component: Register
};

export const ROUTE_LOGIN = {
  path: '/login',
  name: 'login',
  component: Login,
};

export const ROUTE_ROOM_LINK = {
  path: '/room-link',
  name: 'room.link',
  component: RoomLink,
};

export const ROUTE_ROOM_ACCOUNT = {
  path: '/room-account',
  name: 'room.account',
  component: RoomAccount,
};

export const ROUTE_ROOM_CREATION = {
  path: '/room-creation',
  name: 'room.creation',
  component: RoomCreation,
};

export const ROUTE_ROOM_CONFIGURATION = {
  path: 'room-configuration',
  name: 'room.configuration',
  component: RoomConfiguration
};

export const ROUTE_ROOM_TEAM = {
  path: 'room-team',
  name: 'room.team',
  component: RoomTeam
};

export const ROUTE_ROOM_INVITATION = {
  path: '/room-invitation',
  name: 'room.invitation',
  component: RoomInvitation
};

export const ROUTE_ROOM_CONNECTION = {
  path: '/room-connection',
  name: 'room.connection',
  component: RoomConnection
};

export const ROUTE_GAME = {
  path: 'game',
  name: 'room.game',
  component: Game
};

export const ROUTE_ROOM_WIN = {
  path: '/room-win',
  name: 'room.win',
  component: RoomWin
};

export const ROUTE_ROOM_LOSE = {
  path: 'room-lose',
  name: 'room.lose',
  component: RoomLose
};

export const ROUTE_CONTACT = {
  path: '/contact',
  name: 'Contact',
  component: Contact
};

export const ROUTE_ROOM = {
  path: '/room/:pin/',
  name: 'room',
  component: RoomContainer,
  children: [
    ROUTE_ROOM_CONFIGURATION,
    ROUTE_ROOM_TEAM,
    ROUTE_GAME,
    ROUTE_ROOM_WIN,
    ROUTE_ROOM_LOSE
  ]
};

export const ROUTE_FAQ = {
  path: '/faq',
  name: 'Faq',
  component: Faq
};

export const ROUTE_ACTIVATE = {
  path: '/activation',
  name: 'Activation',
  component: Activation,
};

export const ROUTE_PROFILE = {
  path: '/profile',
  name: 'Profil',
  component: Profile,
  meta: {
    requiresAuth: true
  }
};

export const ROUTE_EXERCISES_BUNDLES = {
  path: '/exercises-bundles',
  name: 'exercisesBundles',
  component: ExercisesBundles,
  props: {isCommunity: true}
};

export const ROUTE_MY_EXERCISES_BUNDLES = {
  path: '/my-exercises-bundles',
  name: 'myExercisesBundles',
  component: ExercisesBundles,
  meta: {
    requiresAuth: true
  }
};
export const ROUTE_CREATE_BUNDLE = {
  path: '/bundles/create',
  name: 'createBundle',
  component: BundleConfiguration,
  meta: {
    requiresAuth: true
  }
};

export const ROUTE_UPDATE_BUNDLE = {
  path: '/bundles/:id',
  name: 'updateBundle',
  component: BundleConfiguration,
  meta: {
    requiresAuth: true
  }
};


export const ROUTE_TERMS = {
  path: '/terms',
  name: 'Terms',
  component: Terms
};

export const ROUTE_PRESENTATION = {
  path: '/about',
  name: 'Presentation',
  component: Presentation
};


export const ROUTE_CREATE_EXERCISE = {
  path: '/exercises/create',
  name: 'createExercise',
  component: ExerciseConfiguration,
  meta: {
    requiresAuth: true
  }
};

export const ROUTE_UPDATE_EXERCISE = {
  path: '/exercises/:id',
  name: 'updateExercise',
  component: ExerciseConfiguration,
  meta: {
    requiresAuth: true
  }
};

export const ROUTE_RESET_PASSWORD = {
  path: '/reset-password',
  name: 'resetPassword',
  component: ResetPassword
};

export const ROUTE_DASHBOARD = {
  path: '/dashboard',
  name: 'dashboard',
  component: Dashboard
};

export const ROUTE_404 = {
  path: '/:pathMatch(.*)*',
  name: '404',
  component: NotFound
};

/**
 * Route list
 */
const routes = [
  ROUTE_HOME,
  ROUTE_REGISTER,
  ROUTE_LOGIN,
  ROUTE_ROOM_WIN,
  ROUTE_ROOM_LOSE,
  ROUTE_ACTIVATE,
  ROUTE_PROFILE,
  ROUTE_ROOM_LINK,
  ROUTE_ROOM_CREATION,
  ROUTE_ROOM_ACCOUNT,
  ROUTE_ROOM,
  ROUTE_ROOM_CONNECTION,
  ROUTE_ROOM_INVITATION,
  ROUTE_GAME,
  ROUTE_EXERCISES_BUNDLES,
  ROUTE_MY_EXERCISES_BUNDLES,
  ROUTE_CREATE_BUNDLE,
  ROUTE_UPDATE_BUNDLE,
  ROUTE_TERMS,
  ROUTE_CONTACT,
  ROUTE_PRESENTATION,
  ROUTE_FAQ,
  ROUTE_CREATE_EXERCISE,
  ROUTE_UPDATE_EXERCISE,
  ROUTE_RESET_PASSWORD,
  ROUTE_DASHBOARD,
  ROUTE_404
];


/**
 * Define router
 */
export const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes
});

/**
 * Router event before navigation (middlewares)
 */
router.beforeEach(async (to, from, next) => {
  await store.restored;
  // If user going to room nested routes
  if (to.matched.some(route => route.name === ROUTE_ROOM.name)
    && !(from.name === ROUTE_ROOM_CONNECTION.name
      || from.name === ROUTE_ROOM_CREATION.name
      || from.matched.some(route => route.name === ROUTE_ROOM.name)
    )) {
    next(ROUTE_HOME.path);
  }

  if (to.path === ROUTE_LOGIN.path && store.state.user.user.token && !store.state.user.user.is_guest) {
    next(ROUTE_HOME.path);
  }

  if (to.meta.middlewares && Array.isArray(to.meta.middlewares)) {
    const middlewares = to.meta.middlewares;
    const context = {from, next, router, to};
    const nextMiddleware = nextFactory(context, middlewares, 1);

    return middlewares[0]({...context, next: nextMiddleware});
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getUser.token || store.getters.getUser.is_guest) {
      return next(ROUTE_HOME);
    }
  }

  return next();
});

/**
 * Launch all middlewares recursively
 *
 * @param {*} context
 * @param {*} middlewares
 * @param index
 * @returns
 */
function nextFactory(context, middlewares, index) {
  const middleware = middlewares[index];

  if (!middleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middlewares, index + 1);
    middleware({...context, next: nextMiddleware});
  };
}


export default router;
