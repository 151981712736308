export const FIRST_WIN_VS = {
  name: 'FirstWin',
  displayName: 'Première victoire',
  description: 'Gagnez votre première partie',
  imgPath: 'first_win_vs.svg',
  owned: false
};

export const TEN_WIN_VS = {
  name: 'TenWin',
  displayName: 'Dix victoires',
  description: 'Gagnez dix parties',
  imgPath: 'ten_win_vs.svg',
  owned: false
};

export const TWENTY_WIN_VS = {
  name: 'TwentyWin',
  displayName: 'Vingt victoires',
  description: 'Gagnez vingt parties',
  imgPath: 'twenty_win_vs.svg',
  owned: false
};

export const FIFTY_WIN_VS = {
  name: 'FiftyWin',
  displayName: 'Cinquante victoires',
  description: 'Gagnez cinquante parties',
  imgPath: 'fifty_win_vs.svg',
  owned: false
};

export const HUNDRED_WIN_VS = {
  name: 'HundredWin',
  displayName: 'Cent victoires',
  description: 'Gagnez cent parties',
  imgPath: 'hundred_win_vs.svg',
  owned: false
};

export const FIRST_DEATH = {
  name: 'FirstDeath',
  displayName: 'Première défaite',
  description: 'Perdez votre première partie',
  imgPath: 'first_death.svg',
  owned: false
};

export const FIRST_BUNDLE = {
  name: 'FirstBundle',
  displayName: 'Premier bundle',
  description: 'Créer votre premier bundle',
  imgPath: 'first_bundle.svg',
  owned: false
};

export const REWARDS = [
  FIRST_WIN_VS,
  TEN_WIN_VS,
  TWENTY_WIN_VS,
  FIFTY_WIN_VS,
  HUNDRED_WIN_VS,
  FIRST_DEATH,
  FIRST_BUNDLE
];
